import logo from './img/logo.jpg';
import {useState} from "react";


const year = () => {
  return (new Date()).getFullYear()
}

const nthDate = function(nth_week, nth_day, month) {
  var src_date = new Date();
  console.log(src_date)

  src_date.setDate(1);
  src_date.setMonth(month);


  let day = ((nth_week * 7) - src_date.getDay()) - (Math.abs(nth_day - 6))
  src_date.setDate(day)
  console.log(src_date)
  return [day, src_date];
};


const getNextDateHeader = () => {
  var cur_date = new Date();

  var cur_day = cur_date.getDay();

  //2 for the 2nd week of the month
  //6 is the integer value for saturday (days of the week 0-6)
  var [nth_date, displayDate] = nthDate(2, 6, cur_date.getMonth());
  nthDate(2, 6, (cur_date.getMonth() + 1));
  if (cur_day > nth_date) {
    [nth_date, displayDate] = nthDate(2, 6, (cur_date.getMonth() + 1));
  }
  return displayDate.toLocaleDateString("en-US", {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
}

function App() {
  return (
    <>
      <header>
        <div className="wrap">
          <img alt="logo" src={logo} className="logo"/>
        </div>
      </header>
      <section className="color_main">
        <div className="wrap">
          <div className="info">
            <svg width="80px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
            </svg>
            All patrons must be fully vaccinated against COVID 19 with at least one booster, or have received their most
            recent vaccination within the last six months.
          </div>
        </div>
        <div className="wrap row">
          <article>Second Saturday of every Month<br/><br/>
            <span>
              Next event is<br/> {getNextDateHeader()}<br/>
              8:00 pm. to 1:00 am.
            </span>
            <br/><br/>
            $35 in advance (<a href="https://www.eventbrite.com/e/the-aviary-tickets-491307151917?aff=eand"
                               target="_blank" rel="noreferrer">eventbrite</a>), $40 at the door*<br/>
            18+ to enter<br/>
            <small>Classes and other events are often held at the venue prior to the show. Keep an eye on our fetlife
              discussions for more details.</small>
            <small className="note">* discount for certain affiliate members, including members of local
              TNG, MasT, and similar groups, as well as patrons of Passion 101
              classes within the preceding week.
            </small>
            <br/>
            <a href="https://fetlife.com/groups/33059" target="_blank" rel="noreferrer">
              <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path
                  d="M15.06 7.812c0 3.38-4.04 5.176-7.06 8.19-3.02-3.014-7.06-4.81-7.06-8.19 0-.704.207-1.36.563-1.91a6.565 6.565 0 0 1-.075-2.259 7.462 7.462 0 0 1 .577-1.985A8.386 8.386 0 0 1 2.711.382C2.8.251 2.892.122 2.99 0c-.018.155-.026.31-.03.464a7.546 7.546 0 0 0 .082 1.351 5.83 5.83 0 0 0 .473 1.609 4.119 4.119 0 0 0 .565.884c.128-.014.258-.021.389-.021C6.42 4.287 8 5.925 8 5.925s1.58-1.638 3.53-1.638c.132 0 .263.007.391.021.062-.072.122-.148.18-.227.143-.198.273-.418.386-.657a5.831 5.831 0 0 0 .473-1.609 7.57 7.57 0 0 0 .083-1.351 5.503 5.503 0 0 0-.03-.464c.098.122.19.25.28.382a8.385 8.385 0 0 1 .706 1.276c.267.606.474 1.267.576 1.984.05.359.073.731.061 1.114a6.361 6.361 0 0 1-.139 1.145c.356.55.563 1.207.563 1.91z"/>
              </svg>
            </a>
            <a href="https://www.facebook.com/TheAviaryPhilly/" target="_blank" rel="noreferrer">
              <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/>
              </svg>
            </a>
          </article>
          <article>
            1315 Spruce St.<br/>
            Philadelphia, PA 19107<br/>

            <div className="map">
              <iframe
                title="Aviary Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1630.0494797146557!2d-75.16516074874781!3d39.947340899863875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c6250775d26d%3A0x2446df0edde92931!2s1315%20Spruce%20St%2C%20Philadelphia%2C%20PA%2019107!5e0!3m2!1sen!2sus!4v1714264374844!5m2!1sen!2sus"
                width="100%" height="450" frameBorder="0" style={{"border": 0}} allowFullScreen></iframe>
            </div>
          </article>
        </div>
      </section>
      <section className="black">
        <div className="wrap">
          <h1 className="padded">Our Rooms</h1>
          <article>
            <h3>Front Room/Social Area:</h3>
            This is the first room you will see when you enter into our event. In this room, once you sign in at the
            front desk, you will see a relaxed open area without any active play going on. This room is where you can
            purchase refreshments, use the coat check, patronize our rotating selection of vendors, and hang out, relax,
            and chat with friends.
          </article>
          <article>
            <h3>The Newbie Room:</h3>
            The Newbie Room, despite its name, is not just for Newbies. Anyone who wants to learn a new skill, brush up,
            practice, or generally learn more is welcome. We have a wonderful team of skilled and experienced
            practitioners who are more than happy to share their time and experience with you.
          </article>
          <article>
            <h3>The Main Room:</h3>
            This is where most of the action happens. We have many play stations set up around the room, as well as
            several house tops who are happy to both play with people, and to teach their craft. Feel free to wander,
            explore, and have a good time.
          </article>
          <article>
            <h3>The Playground:</h3>
            The playground is a relaxing place where you can sit, color, enter a blanket fort, or have storytime! If you
            have not tried it, it's highly recommended, and very refreshing.
          </article>
          <article>
            <h3>The Aftercare Room:</h3>
            Once you're done, and need to relax and rejuvenate, head down the hallway on the first floor and rest on a
            sofa, beanbag chair, or regular chair, or just sprawl out on the floor. This is a quiet room specifically for
            relaxation when one is finished a scene.
          </article>
          <article>
            <h3>Other rooms:</h3>
            There are two other rooms that are currently unavailable while our venue goes through renovations.
          </article>
        </div>
      </section>
      <section className="color_gallery">
        <div className="wrap">
          <h1 className="padded">The Gallery - Will Return Soon.</h1>
          <article className="gallery">

          </article>
        </div>
      </section>
      <section className="black">
        <div className="wrap">
          <h1 className="padded">What is The Aviary?</h1>
          <article>
            The Aviary is an improvisational interactive theater night focusing on ontological deconstruction of the
            political
            theory concepts of Contract and Domination, as put forth by Carole Pateman and Charles Mills. It often
            utilizes
            concepts that involve fetish, as well as BDSM.
          </article>
        </div>
      </section>
      <section className="color_rules">
        <div className="wrap">
          <h1 className="padded">Rules Of Play</h1>
          <article>
            Prior to admittance, all attendees must read and sign a form indicating that they have read, understood, and
            agreed to follow the rules of the event. Attendees must also present a current, valid photo ID, and their
            proof of vaccination. All attendees must sign the admission form every time they attend the event. Failure to
            comply with the event rules is grounds for your immediate removal from the event, either temporarily or
            permanently, without a refund of the cost of admittance.
          </article>
        </div>
      </section>
      <section className="black">
        <div className="wrap">
          <h1 className="padded">Contact Us</h1>
          <article>
                <span>
                    <a
                      href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#116;&#104;&#101;&#097;&#118;&#105;&#097;&#114;&#121;&#112;&#104;&#105;&#108;&#108;&#121;&#046;&#099;&#111;&#109;">
                        &#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#116;&#104;&#101;&#097;&#118;&#105;&#097;&#114;&#121;&#112;&#104;&#105;&#108;&#108;&#121;&#046;&#099;&#111;&#109;
                    </a>
                </span>
            <br/><br/>
            <a href="https://fetlife.com/groups/33059" target="_blank" rel="noreferrer">
              <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path
                  d="M15.06 7.812c0 3.38-4.04 5.176-7.06 8.19-3.02-3.014-7.06-4.81-7.06-8.19 0-.704.207-1.36.563-1.91a6.565 6.565 0 0 1-.075-2.259 7.462 7.462 0 0 1 .577-1.985A8.386 8.386 0 0 1 2.711.382C2.8.251 2.892.122 2.99 0c-.018.155-.026.31-.03.464a7.546 7.546 0 0 0 .082 1.351 5.83 5.83 0 0 0 .473 1.609 4.119 4.119 0 0 0 .565.884c.128-.014.258-.021.389-.021C6.42 4.287 8 5.925 8 5.925s1.58-1.638 3.53-1.638c.132 0 .263.007.391.021.062-.072.122-.148.18-.227.143-.198.273-.418.386-.657a5.831 5.831 0 0 0 .473-1.609 7.57 7.57 0 0 0 .083-1.351 5.503 5.503 0 0 0-.03-.464c.098.122.19.25.28.382a8.385 8.385 0 0 1 .706 1.276c.267.606.474 1.267.576 1.984.05.359.073.731.061 1.114a6.361 6.361 0 0 1-.139 1.145c.356.55.563 1.207.563 1.91z"/>
              </svg>
            </a><a href="https://www.facebook.com/TheAviaryPhilly/" target="_blank" rel="noreferrer">
            <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/>
            </svg>
          </a>
          </article>
        </div>
      </section>
      <section>
        <span className="footer">Copyright 2014-{year()}, The Aviary | Site By:
            <a href="http://phantomsera.com/" target="_blank" rel="noreferrer">Phantoms Era Designs</a></span>
      </section>
    </>
  );
}

const ImageModal = ({caption, img, thumb}) => {
  const [clicked, setClicked] = useState(false)

  const open = () => {
    setClicked(true)
  }

  const close = () => {
    setClicked(false)
  }
  return <>
    {clicked && <div className="modal" onClick={close}>
      <img alt={caption} src={img}/>
    </div>
    }
    <span onClick={open} title={caption}>
      <img alt={caption} src={thumb}/>
    </span>
  </>
}

export default App;
